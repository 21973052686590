<div *ngIf="corr" class="maincontainer light-theme">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="docs" [defaultTab]="defaultTab"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="withdraw()"
              >
                withdraw
              </aecom-button>
              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() ? true : null"
                innerClass="btn lg solid green"
                (click)="hasChanges() && save()"
              >
                save
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="Processing"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item
                data-cy="requireResponse"
                label="Does this Correspondence specifically request a response?"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    id="requireResponseYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_issued.RequireResponse
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setRequireResponse(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="requireResponseNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_issued.RequireResponse
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setRequireResponse(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item label="Due Date (Optional)" columnSpan="1" *ngIf="corr.correspondence_issued.RequireResponse; else skipItem">
                <aecom-date
                  id="due"
                  data-cy="due"
                  [weekendsOff]="true"
                  [notValidBefore]="today"
                  [theme]="'light'"
                  [ngValue]="dueDate"
                  (change)="setDate($event)"
                >
                </aecom-date>
              </aecom-form-item>
              <aecom-form-item
                data-cy="receivedToday"
                label="Was this Correspondence received today"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    id="receivedToadyYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_issued.ReceivedToday
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setReceivedToday(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="receivedTodayNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_issued.ReceivedToday
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setReceivedToday(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item label="Received Date (Optional)" columnSpan="1" *ngIf="!corr.correspondence_issued.ReceivedToday; else skipItem">
                <aecom-date
                  id="receivedDate"
                  data-cy="receivedDate"
                  [theme]="'light'"
                  [ngValue]="receivedDate"
                  (change)="setReceivedDate($event)"
                  [notValidAfter]="today"
                >
                </aecom-date>
              </aecom-form-item>
              <aecom-form-item
                data-cy="isConfidential"
                label="Is this Correspondence confidential?"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    id="isConfidentialYes"
                    innerClass="btn solid btn-tab {{
                      corr.correspondence_issued.IsConfidential
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setIsConfidential(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    id="isConfidentialNo"
                    innerClass="btn solid btn-tab {{
                      !corr.correspondence_issued.IsConfidential
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setIsConfidential(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item columnSpan="1">
              </aecom-form-item>
              <aecom-form-item
                label="Correspondence Type (Optional)"
                columnSpan="1"
              >
                <aecom-dropdown
                  id="corrType"
                  data-cy="corrType"
                  [theme]="'light'"
                  placeholder="Select"
                  [data]="corrTypeList"
                  [selectedId]="corr.correspondence_issued.CorrespondenceTypeId"
                  [autocomplete]="true"
                  (ngValueChange)="setCorrType($event)"
                  (change)="setCorrType($event)"
                ></aecom-dropdown>
              </aecom-form-item>
              <aecom-form-item
                label="Key Words"
                columnSpan="1"
              >
                <bbj-tag id="topic" data-cy="topic" [(tagsIn)]="corr.correspondence_issued.Topics" [allTags]="allTags"></bbj-tag>
              </aecom-form-item>
              
            </aecom-form-group>

            <div style="grid-column: span 6;">
              <bbj-selectedUsersTable [title]="userSelectTitle"></bbj-selectedUsersTable>
            </div>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="bbjSelectUserService.workingItem" class="up-contentcontainer">
  <div class="selectUserWindow">
    <bbj-select-user></bbj-select-user>
  </div>
</div>

<ng-template #skipItem>
  <aecom-form-item columnSpan="1"></aecom-form-item>
</ng-template>
