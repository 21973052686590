<div class="container-fluid" *ngIf="corr">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex">
          <div class="header-title">
            <p>
              <a [routerLink]="['/' + contractId + '/list']"> Correspondence List &gt; </a>
              <span> {{ corr.Guid ? "Draft Correspondence" : "Create Correspondence" }}</span>
            </p>

            <h1>
              <i class="icon icon-lg icon-pencil"></i>

              <span style="text-transform: uppercase">{{ title }}</span>
            </h1>
          </div>
        </div>

        <div class="col-6" style="padding: 0">
          <div class="header-actions">
            <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()"> back </aecom-button>

            <aecom-button
              [theme]="'light'"
              [disabled]="!hasChanges() ? true : null"
              innerClass="btn lg solid green"
              (click)="saveDraft()"
            >
              save draft
            </aecom-button>

            <aecom-button data-cy="submit" [theme]="'light'" innerClass="btn lg solid blue" (click)="submit()"> submit </aecom-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <aecom-form-group
          title="General"
          columnSpan="6"
          columns="2"
          style="display: block; margin-bottom: 10px; min-height: 60%"
        >
          <aecom-form-item [label]="'From - Sender Name'" columnSpan="1">
            <aecom-dropdown
            data-cy="from"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="fromList"
              [selectedId]="corr.correspondence_create.From"
              [validate]="attemptToIssue && !corr.correspondence_create.From"
              (ngValueChange)="setFromUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'To - Recipient Name'" columnSpan="1">
            <aecom-dropdown
            data-cy="to"
              [theme]="'light'"
              [autocomplete]="true"
              placeholder="Select"
              [data]="toList"
              [selectedId]="corr.correspondence_create.To"
              [validate]="attemptToIssue && !corr.correspondence_create.To"
              (ngValueChange)="setToUser($event)"
            ></aecom-dropdown>
          </aecom-form-item>
          <aecom-form-item [label]="'Letter Date'" columnSpan="1">
            <aecom-date
              data-cy="letterDate"
              [theme]="'light'"
              [ngValue]="LetterDate"
              (change)="setLetterDate($event)"
              [validate]="!LetterDate && attemptToIssue"
            ></aecom-date>

            <div
              class="error-message"
              *ngIf="!LetterDate && attemptToIssue"
            >
              Please choose correct date.
            </div>
          </aecom-form-item>
          <aecom-form-item [label]="'Organization Reference Number (Optional)'" columnSpan="1">
            <aecom-textbox
            data-cy="referenceNumber"
              [theme]="'light'"
              [(ngValue)]="corr.correspondence_create.OrganizationReferenceNumber"
              (ngValueChange)="setOrgnizationReferenceNumber($event)"
              [validate]="baseFunctionService.isInputInvalid(corr.correspondence_create.OrganizationReferenceNumber)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Reference'" columnSpan="2" [property]="getReferenceLength() + '/' + referenceMaxLength + ' characters'">
            <aecom-textbox
              data-cy="reference"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="referenceMaxLength"
              [(ngValue)]="corr.correspondence_create.Reference"
              (ngValueChange)="setRefernce($event)"
              [validate]="attemptToIssue && (!corr.correspondence_create.Reference || corr.correspondence_create.Reference.trim()==='' || getReferenceLength() > referenceMaxLength) || baseFunctionService.isInputInvalid(corr.correspondence_create.Reference)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item [label]="'Subject'" columnSpan="2" [property]="getSubjectLength() + '/' + subjectMaxLength + ' characters'">
            <aecom-textbox
              data-cy="subject"
              rows="3"
              [notEmpty]="true"
              [theme]="'light'"
              [isMultiLine]="true"
              [maxlength]="subjectMaxLength"
              [(ngValue)]="corr.correspondence_create.Subject"
              (ngValueChange)="setSubject($event)"
              [validate]="attemptToIssue && (!corr.correspondence_create.Subject || corr.correspondence_create.Subject.trim()==='' || getSubjectLength() > subjectMaxLength) || baseFunctionService.isInputInvalid(corr.correspondence_create.Subject)"
            ></aecom-textbox>
          </aecom-form-item>
          <aecom-form-item columnSpan="1" [label]="'Correspondence File'">
            <aecom-upload
              data-cy="correspondenceFile"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="bbjFileListService.getFileList(corrFileType.CorrespondenceFile).fileList$ | async"
              [theme]="'light'"
              (ngValueChange)="baseFunctionService.importFile($event, corrFileType.CorrespondenceFile)"
              (ngDeleteChange)="baseFunctionService.deleteFile($event, corrFileType.CorrespondenceFile)"
            ></aecom-upload>
            <div class="error-message" *ngIf="bbjFileListService.getFileList(corrFileType.CorrespondenceFile).getCurrentFiles().length === 0 && attemptToIssue">Required Field</div>
            <div class="error-message" *ngIf="!(bbjFileListService.getFileList(corrFileType.CorrespondenceFile).fileListNameValid$ | async)">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>

          <aecom-form-item columnSpan="1" [label]="'Attachments (Optional)'">
            <aecom-upload
              data-cy="attachments"
              [isNewStyle]="true"
              [multiFiles]="true"
              [canDelete]="true"
              [data]="bbjFileListService.getFileList(corrFileType.Attachment).fileList$ | async"
              [theme]="'light'"
              (ngValueChange)="baseFunctionService.importFile($event, corrFileType.Attachment)"
              (ngDeleteChange)="baseFunctionService.deleteFile($event, corrFileType.Attachment)"
            ></aecom-upload>
            <div class="error-message" *ngIf="!(bbjFileListService.getFileList(corrFileType.Attachment).fileListNameValid$ | async)">
              File Name cannot include special characters other than '-', '_', '.', '@'
            </div>
          </aecom-form-item>
        </aecom-form-group>
        <div style="grid-column: span 6;">
          <ng-container *ngTemplateOutlet="ccSession">
          </ng-container>
        </div>
        
      </aecom-form>
    </div>
  </div>
</div>

<div *ngIf="bbjSelectUserService.workingItem" class="up-contentcontainer">
  <div class="selectUserWindow">
    <bbj-select-user></bbj-select-user>
  </div>
</div>

<ng-template #ccSession>
  <bbj-selectedUsersTable [title]="userSelectTitle"></bbj-selectedUsersTable>
  <aecom-form-item [label]="'Additional Notes'" columnSpan="6" [property]="getNotesLength() + '/' + notesMaxLength + ' characters'">
    <aecom-textbox
      data-cy="note"
      rows="3"
      [theme]="'light'"
      [isMultiLine]="true"
      [maxlength]="notesMaxLength"
      [(ngValue)]="corr.correspondence_create.Note"
      (ngValueChange)="setNote($event)"
      [validate]="attemptToIssue && getNotesLength() > notesMaxLength || baseFunctionService.isInputInvalid(corr.correspondence_create.Note)"
    ></aecom-textbox>
  </aecom-form-item>
</ng-template>