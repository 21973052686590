import {
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  CorrespondenceReferenceUser,
  ICorrListItem,
} from "@api";
import { AuthService, BBJFileListService, BBJSelectUserService } from "@bbj/components";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import CorrCreate from "@models/corrCreate";
import CorrespondenceFileType from "@models/CorrespondenceFileType";
import CorrespondenceRole from "@models/CorrespondenceRole";
import IViewPrepare from "@models/IViewPrepaer";
import BaseFunctionService from "@services/baseFunction.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import NavService from "@services/nav.service";
import {
  corrDBRoldId,
  correspondenceAppId,
} from "@shared/staticValue";
import {
  getDateWithOffSet,
} from "@shared/utils";
import _, { isString } from "lodash";
import { Observable } from "rxjs";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export default class CreateComponent implements OnInit, ComponentCanDeactivate {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  title: string;

  corr: CorrCreate;

  oldCorr: CorrCreate;

  attemptToIssue = false;

  fromList: ISelectItem[];

  toList: ISelectItem[];

  contractId: string;

  currentUser: string;

  enableSaveDraft = false;

  LetterDate: Date;

  subjectMaxLength = 100;

  referenceMaxLength = 150;

  notesMaxLength = 250;

  userSelectTitle = "cc";

  constructor(
    public navService: NavService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractService: LocalContractService,
    public activeModal: ModalContainerService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public baseFunctionService: BaseFunctionService,
    public bbjFileListService: BBJFileListService,
    public bbjSelectUserService: BBJSelectUserService
  ) {}

  ngOnInit(): void {
    const data: IViewPrepare | undefined =
      this.activatedRoute.snapshot.data.itemData;
    const refUsers: CorrespondenceReferenceUser[] =
      this.activatedRoute.snapshot.data.createStepData;
    this.currentUser = this.navService.getUser().Id;
    this.contractId = this.localContractService.currentContract.Guid;
    const userRole = this.localContractUserService.currentUserContractRole;
    const userEmail = this.navService.getUser().Email;
    if (data) {
      const { item } = data;
      this.loadData(
        refUsers.sort(CreateComponent.compare),
        userRole,
        userEmail,
        item,
      );
    } else {
      this.loadData(
        refUsers.sort(CreateComponent.compare),
        userRole,
        userEmail,
      );
    }
    this.loadingService.stop();
  }

  static compare(
    a: CorrespondenceReferenceUser,
    b: CorrespondenceReferenceUser,
  ): number {
    if (a.Name < b.Name) {
      return -1;
    }
    if (a.Name > b.Name) {
      return 1;
    }
    return 0;
  }

  loadData(
    users: CorrespondenceReferenceUser[],
    userRoles: string,
    userEmail: string | null,
    corr?: ICorrListItem,
  ): void {
    if (userRoles === CorrespondenceRole.DBAdmin) {
      const contractUsers = this.localContractUserService.getItems();
      const contractDBs: CorrespondenceReferenceUser[] = [];
      const contractAHJVs: CorrespondenceReferenceUser[] = [];
      users.forEach((u) => {
        const user = contractUsers.find((cu) => {
          return cu.UserId === u.UserId && cu.DeletedBy === null;
        });
        if (user) {
          const corrRole = user.contract_user_application_role.find((cuar) => {
            return cuar.ApplicationId === correspondenceAppId;
          });
          if (corrRole) {
            if (corrRole.ApplicationRoleId === corrDBRoldId) {
              contractDBs.push(u);
            } else {
              contractAHJVs.push(u);
            }
          }
        }
      });
      this.fromList = this.mappingRefUsers(contractDBs);
      this.toList = this.mappingRefUsers(contractAHJVs);
    } else {
      this.fromList = this.mappingRefUsers(users);
      this.toList = [...this.fromList];
    }

    const dbUser =
      userRoles === CorrespondenceRole.DBAdmin
        ? users.find(
            (u) =>
              u.Email?.toLocaleLowerCase() === userEmail?.toLocaleLowerCase(),
          )
        : undefined;
    this.oldCorr = new CorrCreate(this.contractId, corr, dbUser?.Guid);
    this.LetterDate = this.oldCorr.correspondence_create?.LetterDate
      ? new Date(
          getDateWithOffSet(this.oldCorr.correspondence_create.LetterDate),
        )
      : null;
    if (this.LetterDate) {
      this.oldCorr.correspondence_create.LetterDate =
        this.LetterDate.toISOString();
    }

    this.title = this.oldCorr.Guid
      ? "Draft Correspondence "
      : "Create New Correspondence";
    this.baseFunctionService.initCCUsers(this.userSelectTitle);
    console.log(this.oldCorr);
    this.baseFunctionService.initFileListforDraft();
    if(this.oldCorr.tempId)
    {
      this.baseFunctionService.setTempId(this.oldCorr.tempId);
    }
    this.corr = JSON.parse(JSON.stringify(this.oldCorr));
  }

  mappingRefUsers(users: CorrespondenceReferenceUser[]): ISelectItem[] {
    const contractCode = this.localContractService.currentContract.Code;
    const isPW = contractCode.includes("PW-");
    const adUsers = this.localUserService.getItems();
    return users
      .filter((u) => {
        return !isPW || u.Organization === "AHJV" || u.Organization === "DDC";
      })
      .map((item: CorrespondenceReferenceUser) => {
        let name = item.Name;
        if (!item.UserId || item.UserId === "") {
          name = `${item.Name} (Offline)`;
        }
        else if(adUsers.find((u)=>{return u.id===item.UserId})?.accountEnabled !== true)
        {
          name = `${item.Name} (Offline)`;
        }
        return {
          name: `${name}, ${item.Title ?? "-"}, ${item.Organization ?? "-"}`,
          id: item.Guid,
          checked: false,
        };
      });
  }

  // eslint-disable-next-line class-methods-use-this
  get corrFileType(): typeof CorrespondenceFileType {
    return CorrespondenceFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldCorr);
    const current = JSON.stringify(this.corr);

    return (
      old !== current || this.bbjFileListService.areFilesChanged() || this.bbjSelectUserService.hasChanges()
    );
  }

  IsInputValid(): boolean {
    const inValid = Object.values(this.corr.correspondence_create).find((v) => {
      return isString(v) && v !== null && v !== undefined && this.baseFunctionService.isInputInvalid(v);
    });
    return inValid === undefined;
  }

  IsValid(): boolean {
    if (
      !this.corr.correspondence_create.From ||
      this.corr.correspondence_create.From.trim() === "" ||
      !this.corr.correspondence_create.To ||
      this.corr.correspondence_create.To.trim() === "" ||
      !this.corr.correspondence_create.LetterDate ||
      this.corr.correspondence_create.LetterDate.trim() === "" ||
      !this.corr.correspondence_create.Reference ||
      this.corr.correspondence_create.Reference.trim() === "" ||
      this.corr.correspondence_create.Reference.trim().length >
        this.referenceMaxLength ||
      !this.corr.correspondence_create.Subject ||
      this.corr.correspondence_create.Subject.trim() === "" ||
      this.corr.correspondence_create.Subject.trim().length >
        this.subjectMaxLength ||
      (this.corr.correspondence_create?.Note &&
        this.corr.correspondence_create.Note.trim().length >
          this.notesMaxLength) ||
        this.bbjFileListService.getFileList(CorrespondenceFileType.CorrespondenceFile).getCurrentFiles().length === 0 ||
        !this.bbjFileListService.isFileNameValid()
    ) {
      return false;
    }
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;

    if (!this.IsInputValid()) {
      return;
    }

    const validation = this.IsValid();

    if (!validation) {
      return;
    }

    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit Correspondence?";
      modalInstance.instance.body =
        "Once it's submitted, you will no longer be able to edit.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.corr.correspondence_create.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges() || !this.IsInputValid() || !this.bbjFileListService.isFileNameValid()) return;

    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Correspondence will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.corr.correspondence_create.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    this.corr.correspondence_cc_user = this.bbjSelectUserService.getSelectedUserIds(this.userSelectTitle);
    console.log(this.corr);

    this.baseFunctionService.update(this.corr);
  }

  getSubjectLength(): number {
    return this.corr.correspondence_create?.Subject
      ? this.corr.correspondence_create?.Subject.length
      : 0;
  }

  getReferenceLength(): number {
    return this.corr.correspondence_create?.Reference
      ? this.corr.correspondence_create?.Reference.length
      : 0;
  }

  getNotesLength(): number {
    return this.corr.correspondence_create?.Note
      ? this.corr.correspondence_create?.Note.length
      : 0;
  }

  setFromUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.corr.correspondence_create.From = e.id;
      
    }
  }

  setToUser(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.corr.correspondence_create.To = e.id;
      
    }
  }

  setLetterDate(e: Date | string): void {
    this.LetterDate = new Date(e);
    this.corr.correspondence_create.LetterDate = this.LetterDate.toISOString();
    
  }

  setRefernce(e: string): void {
    this.corr.correspondence_create.Reference = e;
    
  }

  setSubject(e: string): void {
    this.corr.correspondence_create.Subject = e;
    
  }

  setOrgnizationReferenceNumber(e: string): void {
    this.corr.correspondence_create.OrganizationReferenceNumber = e;
    
  }

  setNote(e: string): void {
    this.corr.correspondence_create.Note = e;
    
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

}
