<aecom-modal data-cy="modal" title="WITHDRAW Correspondence?" class="modal-md" [canClose]="false" theme="light">
  <div class="modal-body">
    <aecom-form-group columnSpan="12" columns="1" class="hideHeader">
      <aecom-form-item [label]="'Notes'" columnSpan="1" [required]="true">
        <aecom-textbox
          data-cy="notes"
          rows="3"
          [theme]="'light'"
          [notEmpty]="true"
          [maxlength]="notesMaxLength"
          [isMultiLine]="true"
          [(ngValue)]="item.correspondence_withdraw.Notes"
          [validate]="
            attemptToIssue &&
            (!item.correspondence_withdraw.Notes ||
            getNotesLength() > notesMaxLength) || baseFunctionService.isInputInvalid(item.correspondence_withdraw.Notes)
          "
        ></aecom-textbox>
      </aecom-form-item>
      <aecom-form-item columnSpan="1" label="Attachments" *ngIf="bbjFileListService.getFileList(correspondenceFileType.Attachment)">
        <aecom-upload
          [isNewStyle]="true"
          [multiFiles]="true"
          [canDelete]="true"
          [data]="bbjFileListService.getFileList(correspondenceFileType.Attachment).fileList$ | async"
          [theme]="'light'"
          (ngValueChange)="baseFunctionService.importFile($event, correspondenceFileType.Attachment)"
          (ngDeleteChange)="baseFunctionService.deleteFile($event, correspondenceFileType.Attachment)"
        ></aecom-upload>
        <div class="error-message" *ngIf="!(bbjFileListService.getFileList(correspondenceFileType.Attachment).fileListNameValid$ | async)">
          File Name cannot include special characters other than '-', '_', '.', '@'
        </div>
      </aecom-form-item>
    </aecom-form-group>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">Cancel</aecom-button>

      <aecom-button innerClass="blue lg solid" (click)="save()">Proceed</aecom-button>
    </div>
  </div>
</aecom-modal>
