import { ICorrWithdrawInput } from "../api-generated";

export default class CorrWithdrawDetail
  implements ICorrWithdrawInput
{
  public Notes: string | null;

  constructor() {
    this.Notes = null;
  }
}
