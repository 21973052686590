import { DOCUMENT } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import NavService from "@services/nav.service";
import RouteParams from "@shared/route-params";
import { combineUserInfo } from "@shared/utils";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, concatMap, first, map } from "rxjs/operators";
import environment from "src/environments/environment";
import {
  ContractService,
  ContractUserService,
  CorrespondenceItemService,
  INavigationRoot,
  IUser,
  NavigationService,
  TPContract,
  TPContractUser,
  UserService,
} from "@api";
import { corrDocControlRoleId, correspondenceAppId } from "@shared/staticValue";
import { CorrespondenceTypeService } from "@api/api/correspondenceType.service";
import LocalCorrespondenceTypeService from "@services/local-correspondenceType.service";
import { CorrespondenceType } from "@api/model/correspondenceType";
import { AuthService } from "@bbj/components";

@Injectable({
  providedIn: "root",
})
export default class LoginUserResolve {
  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    public router: Router,
    private contractService: ContractService,
    private contractUserService: ContractUserService,
    private userService: UserService,
    private correspondenceService: CorrespondenceItemService,
    private correspondenceTypeService: CorrespondenceTypeService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public localContractService: LocalContractService,
    public localCorrespondenceTypeServices: LocalCorrespondenceTypeService,
    public navService: NavService,
    public navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    this.loadingService.start();
    console.log("Hitting this");
    const contractId = route.params[RouteParams.ContractId];
    if (!contractId) {
      this.document.location.href = environment.url.portal;
    }

    return from(this.authService.login()).pipe(
      concatMap((user) => {
        if (!user) {
          return of([]);
        }

        const localContract = this.localContractService.getItem();
        const localContractUsers = this.localContractUserService.getItems();
        const localUsers = this.localUserService.getItems();
        const localRole = this.localContractUserService.currentUserContractRole;
        const localCorrType = this.localCorrespondenceTypeServices.getItems();
        const boroughNavigation = this.navService.getBroughData();
        const combinedBoroughs = this.navService.getrecentvalue();
        const globalNavigation = this.navService.getGlobalData();

        if (
          !localContract ||
          !localContractUsers ||
          !localUsers ||
          !localCorrType ||
          !localRole ||
          localContract.Guid !== contractId
        ) {
          return forkJoin([
            this.userService.getAll(),
            this.contractService.getContractById(contractId),
            this.contractUserService.getContractUser(contractId),
            this.correspondenceService.getCorrespondenceRole(contractId),
            this.correspondenceTypeService.getAllCorrespondenceType(),
            this.navigationService.getAllMenus(),
          ]);
        }

        return of([
          localUsers,
          localContract,
          localContractUsers,
          localRole,
          localCorrType,
          { boroughNavigation, combinedBoroughs, globalNavigation },
        ]);
      }),
      map(
        ([users, contract, contractUsers, role, correspondenceType, root]: [
          IUser[],
          TPContract,
          TPContractUser[],
          string,
          CorrespondenceType[],
          INavigationRoot,
        ]) => {
          const loginUser = users.find((u)=>{return u.id===this.authService.getUserId()});
          if (!users || !contract || !loginUser) {
            this.document.location.href = environment.url.portal;
          }
          
          const permission = this.authService.getPermission();
          this.navService.setUserItem(loginUser.givenName, loginUser.surname, loginUser.id, loginUser.mail, loginUser.companyName, permission);
          this.localContractService.currentContract = contract;
          this.localContractUserService.currentUserContractRole = role;

          const doc = contractUsers.find((cu) => {
            return cu.contract_user_application_role.find((a) => {
              return (
                a.ApplicationId === correspondenceAppId &&
                a.ApplicationRoleId === corrDocControlRoleId
              );
            });
          });

          this.localContractUserService.defaultDoc = doc?.UserId ?? null;
          this.localContractService.setItem(contract);

          const contractUsersWithInfo: IContractUserWithUserInfo[] =
            contractUsers
              .map((cu) => {
                const userInfo = users.find((u) => {
                  return u.id === cu.UserId;
                });
                return combineUserInfo(cu, userInfo);
              })
              .filter((cu) => {
                return cu.UserInfo;
              });

          this.localContractUserService.setItems(contractUsersWithInfo);
          this.localUserService.setItems(users);
          this.localCorrespondenceTypeServices.setItems(correspondenceType);

          if (root) {
            this.navService.setBroughData(root.boroughNavigation);
            this.navService.setGlobalData(root.globalNavigation);
            this.navService.setDataSubject(root.combinedBoroughs);

            if (root.globalNavigation.length) {
              this.navService.setSelectedId(
                root.globalNavigation[0].globalModule.Guid,
              );
            } else if (root.boroughNavigation.length) {
              this.navService.setSelectedId(
                root.boroughNavigation[0].boroughItem.Guid,
              );

              this.localContractService.setItem(
                root.boroughNavigation[0].projects[0],
              );
            }
          }
          this.loadingService.stop();
          return;
        },
      ),
      first(),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 404) {
          this.document.location.href = environment.url.portal;
        } else {
          const localContract = this.localContractService.getItem();
          if (localContract?.Guid) {
            this.router.navigate([
              localContract.Guid,
              "error",
              error.status ? error.status : 500,
            ]);
          } else {
            this.router.navigate(["error", error.status ? error.status : 500]);
          }
        }

        return of();
      }),
    );
  }
}
