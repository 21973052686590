import { FileModel } from "@aecom/core";
import { ICorrDocumentDownload, ICorrListItem } from "@api";
import {
  generateUserRowItem,
  getDate,
  getFilesByType,
  getFormatedDate,
  getLastestIssuedItem,
  isFinalStep,
} from "@shared/utils";
import CorrespondenceFileType from "./CorrespondenceFileType";
import CorrespondenceStatus from "./CorrespondenceStatus";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import RowItem from "@bbj/components/lib/components/selectUser/table-row";

export default class CorrView {
  public correspondenceNumber: string;

  public replyBy: string;

  public replyDate: string;

  public letterDate: string;

  public response: string;

  public referenceId: string;

  public note: string;

  public showResult: boolean;

  public noResponse: boolean;

  public ccUsersTableData: RowItem[] = [];

  public ccUsersTableRows: RowItem[] = [];

  public correspondenceFile: FileModel[] = [];

  public attachments: FileModel[] = [];

  constructor(
    item: ICorrListItem,
    docs: ICorrDocumentDownload[],
    users: IContractUserWithUserInfo[],
  ) {
    this.showResult = isFinalStep(item.Status);
    this.noResponse = item.Status === CorrespondenceStatus.Filed;
    if (this.showResult) {
      if (this.noResponse) {
        this.noResponsePrepare(item);
      } else {
        this.responseRrepare(item, docs, users);
      }
    }
  }

  private noResponsePrepare(item: ICorrListItem): void {
    const none = "N/A";
    const lastIssued = getLastestIssuedItem(item);
    if (lastIssued && lastIssued.RequireResponse === false) {
      this.note = lastIssued.Note ?? none;
    } else if (
      item.correspondence_response &&
      item.correspondence_response.length === 1 &&
      item.correspondence_response[0].WantToResponse === false
    ) {
      this.note = item.correspondence_response[0].Note ?? none;
    }
  }

  private responseRrepare(
    item: ICorrListItem,
    docs: ICorrDocumentDownload[],
    users: IContractUserWithUserInfo[],
  ): void {
    const none = "N/A";
    const lastIssued = getLastestIssuedItem(item);
    this.correspondenceNumber = item.CorrespondenceNumber;
    if (
      item.correspondence_response &&
      item.correspondence_response.length === 1
    ) {
      const replyUser = users.find((u) => {
        return u.UserId === lastIssued?.ManagerId;
      });
      this.replyBy = `${item.CorrespondenceNumber}R | Responded by ${
        replyUser?.UserInfo?.displayName ?? " - "
      } | ${replyUser?.UserInfo?.companyName ?? " - "}`;
      this.replyDate = item.correspondence_response[0].DateUpdated
        ? getFormatedDate(item.correspondence_response[0].DateUpdated)
        : none;
      this.letterDate = item.correspondence_response[0].LetterDate
        ? getDate(item.correspondence_response[0].LetterDate)
        : none;
      this.response = item.correspondence_response[0].Response ?? none;
      this.referenceId = item.correspondence_response[0].ReferenceId ?? "-";
      const ccUserIds = item.correspondence_cc_user.map((cc) => cc.UserId);
      this.ccUsersTableData = users
        .filter((user) => {
          return ccUserIds.includes(user.UserId);
        })
        .map((u) => {
          return generateUserRowItem<IContractUserWithUserInfo>(u);
        });
      const documentForThis = docs.filter((doc) => {
        return doc.ReferenceId === item.correspondence_response[0].Guid;
      });
      this.correspondenceFile = getFilesByType(
        documentForThis,
        CorrespondenceFileType.CorrespondenceFile,
      );
      this.attachments = getFilesByType(
        documentForThis,
        CorrespondenceFileType.Attachment,
      );
    }
  }
}
