import { v4 as uuidv4 } from 'uuid';
import { ICorrListItem, ICorrWithdrawStepItem } from "@api";
import CorrespondenceUpdateBase from './CorrespondenceUpdateBase';
import CorrWithdrawDetail from './corrWithdrawDetail';

export default class CorrWithdrawStep
  extends CorrespondenceUpdateBase
  implements ICorrWithdrawStepItem
{
  public correspondence_withdraw: CorrWithdrawDetail;

  constructor(contractId: string, item: ICorrListItem) {
    super(contractId, item);

    this.correspondence_withdraw = new CorrWithdrawDetail();

    this.tempId = uuidv4();
  }
}
