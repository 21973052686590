import { v4 as uuidv4 } from 'uuid';
import { ICorrCreateStepItem, ICorrListItem } from "@api";
import CorrespondenceUpdateBase from "./CorrespondenceUpdateBase";
import CorrCreateBase from "./corrCreateBase";

export default class CorrCreate
  extends CorrespondenceUpdateBase
  implements ICorrCreateStepItem
{
  public correspondence_create: CorrCreateBase;

  public correspondence_cc_user?: string[];

  constructor(contractId: string, item?: ICorrListItem, dbUserId?: string) {
    super(contractId, item);

    this.correspondence_create = item?.correspondence_create
      ? new CorrCreateBase(item.correspondence_create, dbUserId)
      : new CorrCreateBase(undefined, dbUserId);

    if (!item) {
      this.tempId = uuidv4();
    }
    if (item?.correspondence_cc_user) {
      this.correspondence_cc_user = item.correspondence_cc_user.map((user) => {
        return user.UserId;
      });
    } else {
      this.correspondence_cc_user = [];
    }
  }
}
