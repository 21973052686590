import { ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import LoadingService from "./loading.service";
import LocalContractUserService from "./local-contractUser.service";
import _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { v4 as uuidv4 } from 'uuid';
import { lastValueFrom } from "rxjs";
import { FileModel } from "@aecom/core";
import { UploadStatus } from "@aecom/core";
import LocalUserService from "./local-user.service";
import { AuthService, AzureBlobService, BBJFileListService, BBJSelectUserService } from "@bbj/components";
import { generateUserRowItem } from "@shared/utils";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import RowItem from "@bbj/components/lib/components/selectUser/table-row";
import { CorrespondenceDocumentsService, CorrespondenceItemService, ICorrDocumentDownload, ICorrDocumentUploadReturn, ICorrListItem } from "@api";
import CorrespondenceFileType from "@models/CorrespondenceFileType";
import { corrDBRoldId, corrDocControlRoleId, correspondenceAppId } from "@shared/staticValue";
import CorrCreate from "@models/corrCreate";
import CorrespondenceStatus from "@models/CorrespondenceStatus";
import CorrDocumentUpload from "@models/corrDocumentUpload";
import CorrIssue from "@models/corrIssue";
import CorrWithdrawStep from "@models/corrWithdrawStep";


@Injectable({
  providedIn: "root",
})
export default class BaseFunctionService {
  protected entity: ICorrListItem;
  protected contractId: string;
  protected docs: ICorrDocumentDownload[];
  protected tempGuid: string;

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public correspondenceItemService: CorrespondenceItemService,
    public correspondenceDocumentsService: CorrespondenceDocumentsService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public bbjFileListService: BBJFileListService,
    public bbjSelectUserService: BBJSelectUserService
  ) {}

  setEntity(entity: ICorrListItem, docs: ICorrDocumentDownload[]): void {
    this.entity = entity;
    this.contractId = this.entity.ContractId;
    this.docs = docs;
    this.tempGuid = undefined;
  }

  setContractId(contractId: string): void {
    this.contractId = contractId;
    this.entity = undefined;
    this.docs = [];
    this.tempGuid = uuidv4();
  }

  setTempId(id: string): void {
    this.tempGuid = id;
  }

  initFileListforDraft(): void {
    if(this.entity && this.docs)
    {
      this.bbjFileListService.initItem([
        {
          files:this.getFileListForCurrentStep(this.entity.Status, CorrespondenceFileType.CorrespondenceFile),
          title:CorrespondenceFileType.CorrespondenceFile
        },
        {
          files:this.getFileListForCurrentStep(this.entity.Status, CorrespondenceFileType.Attachment),
          title:CorrespondenceFileType.Attachment
        },
      ]);
      
    } else {
      this.bbjFileListService.initItem([
        {files:[],title:CorrespondenceFileType.CorrespondenceFile},
        {files:[],title:CorrespondenceFileType.Attachment},
      ]);
    }
  }

  getFileListForCurrentStep(status: string, documentType: string, refId?: string): FileModel[] {
    return _.uniqBy(
      this.docs.filter((item) => {
        if(refId)
        {
          return item.ReferenceId === refId && item.FileType === documentType;
        }
        return item.Status === status && item.FileType === documentType;
      }).map((item)=>{
        return new FileModel(
          item.Guid, // use document Guid instead of fileId
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL,
        )
      }).sort((a, b) => {
        return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
      }),
      "Name"
    );
  }

  getAllUserTableData(): RowItem[] {
    return this.localContractUserService
      .getItems()
      .filter((user) => {
        return user.contract_user_application_role.find((role) => {
          return role.ApplicationId === correspondenceAppId
        });
      })
      .map((u) => {
        return generateUserRowItem<IContractUserWithUserInfo>(u);
      });
  }

  getAvailableUserTableData(): RowItem[] {
    return this.localContractUserService
      .getItems()
      .filter((user) => {
        return user.contract_user_application_role.find((role) => {
          return (
            role.ApplicationId === correspondenceAppId &&
            role.ApplicationRoleId !== corrDBRoldId &&
            role.ApplicationRoleId !== corrDocControlRoleId
          );
        });
      })
      .map((u) => {
        return generateUserRowItem<IContractUserWithUserInfo>(u);
      });
  }

  initCCUsers(title: string, readonly?: boolean): void {
    const allUsers = this.getAllUserTableData();
    const all = this.getAvailableUserTableData();
    const initData = this.entity ? 
      allUsers.filter((u) => {
        return this.entity.correspondence_cc_user.map((cc)=>cc.UserId).includes(u.id);
      }) : [];
    this.bbjSelectUserService.initItem([{title, all, initData, readonly}]);
  }

  importFile(e: FileModel[], type: string): void {
    let status = "";
    let itemId = "";
    let refId: string | undefined = undefined;
    const fileList = this.bbjFileListService.getFileList(type);
    if(!this.entity)
    {
      status = CorrespondenceStatus.Draft;
      itemId = this.tempGuid;
    } else if(this.entity.Status === CorrespondenceStatus.Draft) {
      status = CorrespondenceStatus.Draft;
      itemId = this.entity.Guid;
    } else {
      itemId = this.entity.Guid;
      status = CorrespondenceStatus.Void;
      refId = this.tempGuid;
    }
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new CorrDocumentUpload(
            item.Name,
            this.authService.getUserId(),
            type,
            status,
            itemId,
            refId
          );

          await lastValueFrom(this.correspondenceDocumentsService
            .createCorrespondenceDocument(fileUploaded))
            .then((r: ICorrDocumentUploadReturn) => {
              item.Guid = r.Guid;
              fileList.updateFileList(item);
              AzureBlobService.uploadFile(r.URL, item);
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          fileList.deleteFile(item);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    const fileList = this.bbjFileListService.getFileList(type);
    fileList.deleteFile(e);
  }

  errorHandle(error: HttpErrorResponse, activeModal: ModalContainerService, router: Router): void {
    if (error.status === 403 || error.status === 409) {
      const modalInstance1 = activeModal.open(NotificationComponent);
      modalInstance1.instance.type = NotificationType.Information;
      modalInstance1.instance.theme = "light";
      modalInstance1.instance.title = "Your Changes Can't be Saved";
      modalInstance1.instance.body = "Someone already saved changes. Please refresh your screen to see updates.";
      modalInstance1.result.then((result1) => {
        if (result1 === 0) {
          const currentUrl = router.url;
          router.navigateByUrl("blank").then(() => {
            router.navigateByUrl(currentUrl);
          });
        }
      });
    } else {
      console.log(error);
      const modalInstance = activeModal.open(NotificationComponent);
      modalInstance.instance.type = NotificationType.Information;
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Error";
      modalInstance.instance.body = "An unexpected error occurred";
    }
  }

  back(): void {
    this.router.navigateByUrl(`${this.contractId}/list`);
  }

  backAfterConfirm(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Cancel editing?";
    modalInstance.instance.body = "Your changes will not be saved.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      }
    });
  }

  update(item: CorrCreate | CorrIssue | CorrWithdrawStep) {
    this.bbjFileListService.updateFiles(item);
    this.correspondenceItemService.corrUpdate(item).subscribe({
      complete: () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      error: (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    });
  }

  isInputInvalid(str: string | null | undefined): boolean {
    if(str)
    {
      const invalidChars = /[^\x20-\x7E\n\r]+/;
      return invalidChars.test(str);
    }
    return false;
  }
}

