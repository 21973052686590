enum CorrespondenceStatus {
  Draft = "Draft",
  Issued = "Issued",
  In_Review = "In Review",
  Responded = "Responded",
  Response_Requested = "Response Requested",
  Filed = "Filed",
  Void="Void",
}

export default CorrespondenceStatus;
